import { get as getFeatureTacking, set as setFeatureTracking } from './index'

export const SET_FEATURE_TRACKING_DATA = 'SET_FEATURE_TRACKING_DATA'
export const SET_FEATURE_TRACKING_PROCESSING = 'SET_FEATURE_TRACKING_PROCESSING'
export const RESET_FEATURE_TRACKING = 'RESET_FEATURE_TRACKING'
export const INCREMENT_FEATURE_IMPRESSION_COUNT = 'INCREMENT_FEATURE_IMPRESSION_COUNT'
export const RESET_FEATURE_IMPRESSION_COUNT = 'RESET_FEATURE_IMPRESSION_COUNT'
export const SET_FEATURE_TRACKING_COUNT = 'SET_FEATURE_TRACKING_COUNT'
export const ADD_HIDDEN_PM_SECTION = 'ADD_HIDDEN_PM_SECTION'

export function setFeatureTrackingProcessing (value) {
  return {
    type: SET_FEATURE_TRACKING_PROCESSING,
    payload: value,
  }
}

export function setFeatureTrackingData (data, processing = false) {
  return {
    type: SET_FEATURE_TRACKING_DATA,
    payload: { data, processing },
  }
}

export function resetFeatureTracking () {
  return {
    type: RESET_FEATURE_TRACKING,
  }
}

export function incrementFeatureImpressionCount (featureName) {
  return {
    type: INCREMENT_FEATURE_IMPRESSION_COUNT,
    payload: { featureName },
  }
}

export function resetFeatureImpressionCount (featureName) {
  return {
    type: RESET_FEATURE_IMPRESSION_COUNT,
    payload: { featureName },
  }
}

export function setFeatureTrackingCount (data) {
  return {
    type: SET_FEATURE_TRACKING_COUNT,
    payload: { data },
  }
}

export function getFeatureTrackingData (options) {
  const { auth } = options
  return async function getFeatureTrackingDataThunk (dispatch) {
    dispatch(setFeatureTrackingProcessi_FLAG],
  ['curryRight', WRAP_CURRY_RIGHT_FLAG],
  ['flip', WRAP_FLIP_FLAG],
  ['partial', WRAP_PARTIAL_FLAG],
  ['partialRight', WRAP_PARTIAL_RIGHT_FLAG],
  ['rearg', WRAP_REARG_FLAG]
];

/**
 * Updates wrapper `details` based on `bitmask` flags.
 *
 * @private
 * @returns {Array} details The details to modify.
 * @param {number} bitmask The bitmask flags. See `createWrap` for more details.
 * @returns {Array} Returns `details`.
 */
function updateWrapDetails(details, bitmask) {
  arrayEach(wrapFlags, function(pair) {
    var value = '_.' + pair[0];
    if ((bitmask & pair[1]) && !arrayIncludes(details, value)) {
      details.push(value);
    }
  });
  return details.sort();
}

module.exports = updateWrapDetails;
